@import "../../globalScss/global";



@media screen and (min-width: $large-screen) {

  .Services {
    width: 100%;
    margin-top: 14vh;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: $medium-screen) {
  .Services {
    width: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
  }
}
