@import "../../globalScss/global";

$theme-red: #f0013c;
$theme-black: #000000;

.career {
  color: $theme-red;
  background-color: white;
  padding-top: 2rem;
  background: #000;

  .back-home {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: left;
    color: #a6a6a6;
    font-family: Satoshi;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.52px;
    text-transform: uppercase;
    padding: 1.3rem;
    cursor: pointer;
    img {
      width: 1.5em;
      height: 1.5em;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  // .filter-wrap{
  //   overflow-x: scroll;
  //   width: 100%;
  // }
  .roles-filter-container {
    display: flex;
    background: black;
    color: white;
    flex-wrap: wrap;
    font-family: 'Satoshi';
    margin-bottom: 5vh;
    .role {
      padding: .5em 1em;
      line-height: 2em;
      // border-radius: 0.5em;
      border-radius:35px;
      border: 1px solid white;
      margin: 0.5em 1em;
      cursor: pointer;
    }
    .selected {
      background: red;
    }
  }
  .career-header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
    padding: 2rem;

    .career-titel {
      margin: 1rem;
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: "Satoshi-Black";
      font-size: 38px;
      font-style: normal;
      font-weight: 900;
      line-height: 1em;
      letter-spacing: 1.92px;
      text-transform: uppercase;
    }

    .career-sub {
      font-size: 0.8em;
      margin: 0;
      color: #b7b7b7;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Satoshi;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5em;
      letter-spacing: 0.8px;
    }
  }

  .item {
    // overflow-y: scroll;
    // height: 800px;
    margin: 10px;
    border-radius: 24px;
    border: 1px solid #fff;
    padding: 1em;
    .title {
      position: sticky;
      top: 0px;
      border-radius: 20px;
      background: #0e0e0e;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1em;
      margin-bottom: 2rem;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        .apply-container {
          margin-top: 1rem;
        }
      }

      .role {
        color: #fff;
        font-family: Satoshi;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2em;
        letter-spacing: 2.16px;
        text-align: left;
        @media screen and (max-width: 600px) {
          font-size: 24px;
        }
      }
      .location {
        color: #a7a7a7;
        font-family: Satoshi;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 176.523%; /* 35.305px */
        letter-spacing: 1.2px;
        text-align: left;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
    .apply-container {
      display: flex;
      flex-direction: row;
      transition: flex-direction 10s;
      img {
        width: 50px;
        height: 50px;
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .apply-button {
        display: flex;
        width: 200px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 32px;
        border: 1px solid #ff2157;
        background: #000;
        margin: 0 1rem;
        font-family: Satoshi;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          margin: 0;
        }
      }
      &:hover {
        flex-direction: row-reverse;
        .apply-button {
          background: white;
        }
      }
    }
    .description {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .about {
        width: 40%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        .about-title {
          color: #fff;
          font-family: Satoshi;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 48px */
          letter-spacing: 1.92px;
          text-align: left;
          margin-bottom: 1rem;
        }
        .about-detail {
          color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: Satoshi;
          // font-size: 20px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 1.2px;
          text-align: left;
          margin-bottom: 2rem;
        }
      }
      .qualifications {
        width: 60%;
        max-width: 40%;
        margin-left: 20%;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-left: 0;
          max-width: 100%;
        }
        .qualifications-title {
          color: #fff;
          font-family: Satoshi;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 139%; /* 44.48px */
          letter-spacing: 1.92px;
          text-align: left;
          margin-bottom: 1rem;
        }
        .qualifications-details {
          color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: Satoshi;
          // font-size: 20px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 1.2px;
          text-align: left;
          margin-bottom: 2rem;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      .responsibilities {
        width: 100%;
        .responsibilities-title {
          color: #fff;
          font-family: Satoshi;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 139%; /* 44.48px */
          letter-spacing: 1.92px;
          text-align: left;
          margin-bottom: 1rem;
          p{
            color: #fff;
            font-family: Satoshi;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 139%;
            letter-spacing: 1.92px;
            text-align: left;
            margin-bottom: 1rem;
          }
        }
        .responsibilities-details {
          color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: Satoshi;
          // font-size: 20px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 1.2px;
          text-align: left;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
