
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@media screen and (min-width: 1024px) {

    .service-slider {
        width: 100%;
        position: relative;
        bottom: 10vh;
    }
    .latest{
            color: #FFFFFF;
            font-size: 3vw;
            font-family: Satoshi-Bold;
            text-align: start;
            padding-left: 3vw;
        }

    .slide div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: Satoshi;
        background-color: #fff;
        margin-top: 1.01vh;
        margin-bottom: 1vh;
        margin-right: .1%;
        border: 4px solid white;
        border-radius: 15px;
        place-items: center;
    }

    .slide .cover {
        display: none;
    }

    .activeSlide .cover {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0.1%;

    }

    .slide div h1 {
        font-size: 1.5vw;
        color: #000;
        font-family: Satoshi-Bold;
        margin-bottom: 0;
        animation: slideUp 800ms linear;

    }

    .slide div p {
        display: none;
        animation: slideUp 800ms linear;


    }

    .slide div a {
        display: none;
        animation: slideUp 900ms linear;


    }

    .slide div a button {
        display: none;
        animation: slideUp 900ms linear;
        cursor: pointer;



    }

    .slide div a button:hover {
        background-position: left;
        color: #ff2157;
        border: 2px solid #ff2157;

    }


    .slide div a {
        color: #fff;
        text-decoration: none;
        width: 85%;
        display: flex;
        justify-content: center;
        margin-bottom: 2vh;
    }


    .activeSlide div p {
        display: block;
        font-size: 1.1vw;
        color: #000;
        font-family: Satoshi-Light;
        padding: 0 .5vw;

    }

    .activeSlide div a button {
        display: block;
        color: #fff;
        outline: none;
        border-style: none;
        width: 85%;
        height: 6vh;
        font-size: 1.5vw;
        border-radius: 15px;
        z-index: 2;
        background: linear-gradient(to left, #ff2157 50%, #fff 50%) right;
        background-size: 200%;
        transition: .3s ease-out;

    }

    .slide img {
        width: 60vw;
        height: 100%;
        margin: 0 auto;
        border-radius: 18px;
    }

    .slide {
        padding: 0 .5vw;
        transition: transform 500ms .5s ease-in-out;
        opacity: 0.5;
    }

    .activeSlide {
        transform: scale(1);
        opacity: 1;
    }

    .arrow {
        background-color: transparent;
        position: absolute;
        cursor: pointer;
        z-index: 10;
        color: #FFFFFF;

    }

    .arrow svg {
        transition: color 300ms;
    }

    .arrow svg:hover {
        color: #f8092d;
    }

    .next {
        right: 36%;
        bottom: 8%;
        font-size: 2vw;
    }

    .prev {
        left: 36%;
        bottom: 8%;
        font-size: 2vw;

    }

    .slide-button a:link {
        color: #fff;
    }


    @keyframes slideUp {
        0% {
            transform: translatey(40%);
            opacity: 0;
        }

        100% {
            transform: translate(0);
            opacity: 1;

        }

    }
}


@media screen and (max-width: 768px) {
    .service-slider {
        width: 100%;
        margin-bottom: 4rem;
    }
    .latest{
        color: #FFFFFF;
        font-size: 7vw;
        font-family: Satoshi-Bold;
        text-align: center;
    }

    .slide div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: Satoshi;
        background-color: #fff;
        border: 4px solid white;
        border-radius: 45px;
        height: 100%;
    }

    .slide .cover {
        display: none;
    }

    .activeSlide .cover {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0.1%;
    }

    .slide div h1 {
        font-size: 6vw;
        color: #000;
        font-family: Satoshi-Bold;
        margin-bottom: 0;
    }

    .slide div p {
        display: none;
        animation: slideUp 800ms linear;

    }

    .slide div a {
        display: none;
        animation: slideUp 900ms linear;


    }

    .slide div a button {
        display: none;
        animation: slideUp 900ms linear;


    }

    .slide div a button:hover {
        background-position: left;
        color: #ff2157;
        border: 2px solid #ff2157;

    }


    .slide div a {
        color: #fff;
        text-decoration: none;
        width: 85%;
        display: flex;
        justify-content: center;
        margin-bottom: 2vh;
    }


    .activeSlide div a button {
        display: block;
        color: #fff;
        outline: none;
        border-style: none;
        width: 85%;
        height: 6vh;
        font-size: 6vw;
        border-radius: 15px;
        z-index: 2;
        background: linear-gradient(to left, #ff2157 50%, #fff 50%) right;
        background-size: 200%;
        transition: .3s ease-out;

    }

    .activeSlide div p {
        display: block;
        font-size: 4vw;
        width: 50vw;
        color: #000;
        font-family: Satoshi-Light;
        padding: 0 .5vw;

    }


    .slide img {
        width: 66vw;
        height: 100%;
        margin: 0 auto;
        border-radius: 18px;
    }

    .slide {
        padding: 0 .5vw;
        transition: transform 300ms;
        opacity: 0.5;
    }

    .activeSlide {
        transform: scale(1);
        opacity: 1;
    }

    .arrow {
        background-color: transparent;
        position: absolute;
        cursor: pointer;
        z-index: 10;
        color: #FFFFFF;

    }

    .arrow svg {
        transition: color 300ms;
    }

    .arrow svg:hover {
        color: #f8092d;
    }

    .next {
        right: 5%;
        bottom: -6%;
        font-size: 9vw;
    }

    .prev {
        left: 5%;
        bottom: -6%;
        font-size: 9vw;

    }

    .slide-button a:link {
        color: #fff;
    }


    @keyframes slideUp {
        0% {
            transform: translatey(40%);
            opacity: 0;
        }

        100% {
            transform: translate(0);
            opacity: 1;

        }

    }

}


