@import "../../../../globalScss/global";

@media screen and (min-width: $large-screen) {
  .client-heading{
    font-family: $Poppins-Bold;
    display: flex;
    align-self: center;
    color: #FFFFFF;
    font-size: 3vw;

  }

  .service-slide-div {
    width: 90%;
    margin-top: 5vh;
    //margin-bottom: 3vh;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    border-radius: 10px;
    background-color: #121212;
    padding: 3vh 0;

    .slide-items {
      display: inline-block;
      animation: 25s slide infinite linear;

      img {
        margin: 0 3vw;
        width: 7vw;
        aspect-ratio: 3/2;
      }

    }


    &:before {
      left: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), #000);
      @include wrapper;
    }

    &:after {
      right: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #000);
      @include wrapper;
    }

    &:hover {
      .slide-items {
        animation-play-state: paused;
      }
    }

  }
}




@media screen and (max-width: $medium-screen) {
  .client-heading{
    font-family: $Poppins-Bold;
    display: flex;
    align-self: center;
    color: #FFFFFF;
    font-size: 7vw;

  }

  .service-slide-div {
    width: 100%;
    margin-top: 1vh;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    //border-radius: 5px;
    background-color: #121212;
    padding: 3vh 0;

    .slide-items {
      display: inline-block;
      animation: 25s slide infinite linear;
      width: max-content;

      img {
        margin: 0 3vw;
        width: 13vw;
        aspect-ratio: 3/2;
      }

    }


    &:before {
      left: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), #000);
      @include wrapper;
    }

    &:after {
      right: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #000);
      @include wrapper;
    }

    &:hover {
      .slide-items {
        animation-play-state: paused;
      }
    }

  }
}