@import "../../../../globalScss/global";

@media screen and (min-width: $large-screen) {

  .serviceCardSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $Poppins-Regular;
    position: relative;

    bottom: 15vh;

    .serviceCardSection_heading {
      width: 98%;
      border-radius: 137px;
      background: rgba(55, 55, 55, 0.40);
      backdrop-filter: blur(20px);
      color: #fff;
      text-transform: uppercase;
      font-size: 2vw;
      letter-spacing: .5vw;
    }

    .r-serviceCardContent {
      width: 90%;
      background: linear-gradient(180deg, hsla(0, 0%, 0%, 1) 70%, hsla(345, 100%, 50%, 1) 100%);
      border-bottom-right-radius: 55px;
      border-bottom-left-radius: 55px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      justify-content: center;
      padding-bottom: 25vh;
      row-gap: 8vh;
      text-align: center;
      padding-top: 12vh;
      //display: flex;
      //column-gap: 10vw;
      //flex-wrap: wrap;


    }
  }
}


@media screen and (max-width: $medium-screen) {

  .serviceCardSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $Poppins-Regular;
    position: relative;
    bottom: 5vh;

    .serviceCardSection_heading {
      width: 98%;
      border-radius: 137px;
      background: rgba(55, 55, 55, 0.40);
      backdrop-filter: blur(20px);
      color: #fff;
      text-transform: uppercase;
      font-size: 3vw;
      letter-spacing: .5vw;
    }

    .r-serviceCardContent {
      width: 90%;
      background: linear-gradient(180deg, hsla(0, 0%, 0%, 1) 70%, hsla(345, 100%, 50%, 1) 100%);
      border-bottom-right-radius: 55px;
      border-bottom-left-radius: 55px;
      justify-items: center;
      justify-content: center;
      padding-bottom: 5vh;
      row-gap: 8vh;
      text-align: center;
      padding-top: 5vh;
      display: flex;
      flex-direction: column;
      align-items: center;


    }
  }
}