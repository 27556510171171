@import '../../../../../globalScss/global';


/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: $medium-screen) {
  .dropdown__group {
    padding-left: 1.5rem;

  }
  .nav-contact-btn {
    display: none;
  }
}

/* For large devices */
@media screen and (min-width: $large-screen) {

  /* Nav */
  .nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    padding-top: 5vh;
    font-family: $Satoshi;
    position: fixed;
    top: 0;
    background-color: black;


    &__toggle {
      display: none;
    }

    &__list {
      display: flex;
      column-gap: 3rem;
      height: 100%;
    }

    & li {
      display: flex;
    }

    &__link {
      padding: 0;
      color: $grey;


      &:hover {
        background-color: initial;
        color: $red;
      }
    }
    #nav__link{
      display: none;
    }

    .nav-contact-btn {

      border: 2px solid $red;
      color: $red;
      // padding: 1% 3%;
      padding: 15px 45px;
      border-radius: 35px;
      font-size: 1vw;
      font-weight: 600;
      z-index: 2;
      position: relative;
      background: linear-gradient(to left, #000 50%, $red 50%) right;
      background-size: 200%;
      transition: .3s ease-out;
      cursor: pointer;
      a {
        color: $red;
      }

      &:hover {
        border: 2px solid $red;
        color: #fff;
        background-position: left;

        a {
          color: #fff;
        }
      }
    }
  }

  /* Dropdown */
  .dropdown {
    &__button {
      column-gap: .25rem;
      pointer-events: none;

      &:hover {
        color: $red;
      }
    }


    &__container {
      height: max-content;
      position: absolute;
      left: 0;
      right: 0;
      top: 6.5rem;
      background-color: black;
      box-shadow: 0 6px 8px hsla(220, 68%, 12%, .05);
      pointer-events: none;
      opacity: 0;
      transition: top 1s, opacity 1s;
      border-block-end: 2px solid #ffffff;
      border-end-end-radius: 80px 80px;
      border-end-start-radius: 80px 80px;
      border-image-slice: 1;
      text-transform: uppercase;

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }

    &__content {
      grid-template-columns: repeat(3, 30vw);
      margin-inline: auto;
      justify-content: center;
      text-align: start;

      ul {
        grid-template-rows: repeat(4, 1fr);
        grid-auto-flow: column dense;
        position: relative;
        bottom: 3.5vh;
      }

      //> :nth-child(2) {
      //  //grid-column: 2/4;
      //
      //  ul {
      //    grid-template-columns: repeat(2, 1fr);
      //  }
      //}
    }

    &__group {
      &:first-child {
        margin: 0;
        border-bottom: 3px solid $grey;
      }

      &:last-child {
        margin: 0;
      }

      &:nth-child(2) {
        border-bottom: 3px solid $grey;
      }

      &:nth-child(3) {
        border-bottom: 3px solid $grey;
      }

      &:nth-child(4) {
        border-bottom: 3px solid $grey;
      }

      &:nth-child(5) {
        border-bottom: 3px solid $grey;
      }
      &:nth-child(6) {
        border-bottom: 3px solid $grey;
      }


      //padding: 1rem 0;
      align-content: baseline;

    }

    &__list-heading {
      display: flex;
      align-items: center;
      column-gap: 2vh;
      font-size: 2vw;
      position: relative;
      left: 2.5vw;
    }

    &__list {
      row-gap: 1.2rem;
      font-size: 1vw;
      .zero-trust{
        position: relative;
        right: 5vw;
      }
    }


    &__title {
    }


    &__item {
      cursor: pointer;

      // Rotate dropdown icon
      &:hover .dropdown__arrow {
        transform: rotate(180deg);
        color: $red;
      }

      &:hover .dropdown__button {
        color: $red;
      }

      // Show dropdown menu
      &:hover > .dropdown__container {
        top: 5.5rem;
        opacity: 1;
        pointer-events: initial;
        cursor: initial;
        height: 88vh;
        overflow-y: scroll;
      }
    }
  }
}

