@import "../../globalScss/global";


@media screen and (min-width: $large-screen) {

  footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: $Poppins-Medium;
    background: url("../../assets/homePage/footer/background.png") no-repeat center;
    background-size: cover;
    row-gap: 5vh;
    color: #fff;
    text-align: start;

    .upper-footer {
      display: flex;
      justify-content: space-around;
      //margin-top: 2vh;


      h5 {
        font-family: $Poppins-Bold;
        font-size: 2.6vw;
        margin-bottom: 0;
      }

      ul {
        display: contents;
        row-gap: 3vh;

        a {
          color: #fff;
        }


        li {
          display: flex;
          flex-direction: column;
          list-style: none;
          width: 20vw;
          line-height: 178%;
          letter-spacing: .1vw;
          font-family: $Poppins-Regular;
        }
      }

      .home {
        ul {
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          grid-template-columns: repeat(2, 12vw);
          position: relative;
          right: 3vw;
        }
      }

      .location {
        h5 {
          margin-bottom: 2vh;
        }
        ul{
          li{
            margin-bottom: 1vh;
          }
        }
        img {
          width:60%;
        }
      }

      .contact {
        ul {
          display: grid;
          grid-template-rows: repeat(2, 1fr);
          position: relative;
          right: 3vw;
        }

      }
    }

    .lower-footer {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-self: center;

      .lower-left {
a{
  color: #FFFFFF;
}
      }

      .lower-right {
        display: flex;
        column-gap: 5vw;

        .social {

        }
      }
    }
  }
}


@media screen and (max-width: $medium-screen) {
  footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: $Poppins-Medium;
    background: linear-gradient(180deg, #FF003E 0%, rgba(255, 0, 62, 0.00) 100%);
    //background-size: cover;
    row-gap: 10vh;
    color: #fff;
    text-align: start;

    .upper-footer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 5vw;
      //margin-top: 10vh;


      h5 {
        font-family: $Poppins-Bold;
        font-size: 5vw;
        margin-bottom: 3vh;
      }

      ul {
        display: contents;
        row-gap: 5vh;

        a {
          color: #fff;
        }

        li {
          display: flex;
          flex-direction: column;
          list-style: none;
          width: 45vw;
          line-height: 178%;
          letter-spacing: .1vw;
          font-family: $Poppins-Regular;
          font-size: 3vw;
        }
      }

      .home {
        display: grid;
        grid-row: 1/3;

      }

      .contact {
        display: grid;
        grid-column: 2/3;
        justify-content: end;

      }
      .location{
        ul{
          li{
            margin-bottom: 1vh;
          }
        }
        img {
          width:70%;
        }
      }
    }

    .lower-footer {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-self: center;

      .lower-left {
        font-size: 2.5vw;
        a{
          color: #FFFFFF;
        }
      }

      .lower-right {
        display: flex;
        column-gap: 5vw;
        font-size: 2.5vw;

        .social {
          img {
            width: 8vw;
            aspect-ratio: 3/2;
          }
        }
      }
    }
  }
}
