@import "../../../globalScss/global";


.navContent{
  width: 100%;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5vh;
  .articleContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: start;
    h1{
    font-family: $Poppins-Bold;
      font-size: 2.5vw;
      color: $red;
      margin: 0;
    }
    p{
      font-family: $Poppins-Regular;
      font-size: 1.5vw;
      color: #fff;
    }
  }
}