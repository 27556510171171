@import "../../../../globalScss/global";

@media screen and (min-width: $large-screen) {

  .g-ServicesCard {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    height: 52vh;
    width: 90%;
    @include background;


    .s-card {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      background-color: #000000;

      h1 {
        font-size: 2rem;
        color: #ffffff;
        text-align: center;
        position: relative;
        bottom: 9vh;
        width: 20vw;
        font-family: $Poppins-Bold;
        text-transform: uppercase;
      }

      //.webGameh1 {
      //  width: 55%;
      //}
      //
      //.Rewardsh1 {
      //  width: 70%;
      //}
      //
      //.arh1 {
      //  width: 8vw;
      //}


      .s-clip {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        inset: 0;
        display: flex;
        background-color: $red;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        clip-path: circle(37.7% at 50% 100%);
        //clip-path: circle(100%);
        transition: clip-path 800ms ease-in, color 1000ms linear;

        //.default-img {
        //  transform: translateY(6vh);
        //  transition: 500ms ease-in, color 1000ms linear;
        //}

        h2 {
          text-transform: uppercase;
          font-size: 1.5rem;
          font-family: $Poppins-Bold;
          width: 15vw;
          text-align: center;
          margin-top: 0;
          opacity: 0;
          transition: 800ms ease-in-out, color 1000ms linear, opacity 0.2s ease-in-out .3s;
          color: #FFFFFF;
          position: absolute;
          top: 64%;
        }

        p {
          font-family: $Poppins-Medium;
          font-size: 1vw;
          width: 80%;
          text-align: center;
          margin: 0;
          opacity: 0;
          transition: 800ms ease-in-out, color 1000ms linear, opacity 0.2s ease-in-out .3s;
          color: #FFFFFF;
          position: absolute;
          top: 64%;
        }

        //.default-img {
        //  max-width: 5vw;
        //  position: absolute;
        //  top: 64%;
        //  transition: top 800ms ease-in-out;
        //
        //  img {
        //    width: 5vw;
        //  }
        //}

        &:hover {
          clip-path: circle(100%);

          //.default-img {
          //  top: 5%;
          //}

          h2 {
            transition: 500ms ease-in, color 1000ms linear, opacity 0.5s linear .5s;
            opacity: 1;
            top: 10%;
          }

          p {
            opacity: 1;
            transition: 500ms ease-in-out, color 1000ms linear, opacity 0.5s linear .5s;
            top: 40%;
          }

        }
      }

    }
  }
}


@media screen and (max-width: $small-screen) {
  .g-ServicesCard {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    height: 45vh;
    width: 65%;
    @include background;


    .s-card {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      background-color: #000000;

      h1 {
        font-size: 6vw;
        color: #ffffff;
        text-align: center;
        position: relative;
        bottom: 9vh;
        width: 34vw;
        font-family: $Poppins-Bold;
      }

      .arh1 {
        width: 18vw;
      }


      .s-clip {
        z-index: 1;
        position: absolute;
        inset: 0;
        display: flex;
        background-color: $red;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        clip-path: circle(37.7% at 50% 100%);
        transition: clip-path 800ms ease-in, color 1000ms linear;

        //.default-img {
        //  transform: translateY(6vh);
        //  transition: 500ms ease-in, color 1000ms linear;
        //}

        h2 {
          margin-top: 8vh;
          font-size: 6vw;
          font-family: $Poppins-Bold;
          width: 34vw;
          text-align: center;
          margin-top: 0;
          opacity: 0;
          transition: 800ms ease-in-out, color 1000ms linear, opacity 0.2s ease-in-out .3s;
          position: absolute;
          top: 64%;
          color: #FFFFFF;



        }

        .arh2 {
          width: 18vw;
        }

        p {
          font-family: $Poppins-Medium;
          font-size: 2.5vw;
          width: 85%;
          text-align: center;
          margin: 0;
          opacity: 0;
          transition: 800ms ease-in-out, color 1000ms linear, opacity 0.2s ease-in-out .3s;
          position: absolute;
          top: 64%;
          color: #FFFFFF;

        }

        //.default-img {
        //  max-width: 8vw;
        //  position: absolute;
        //  top: 64%;
        //  transition: top 800ms ease-in-out;
        //
        //  img {
        //    width: 8vw;
        //  }
        //}

        &:hover {
          clip-path: circle(100%);
          overflow-y: hidden;

          //.default-img {
          //  top: 2%;
          //
          //}

          h2 {
            transition: 500ms ease-in, color 1000ms linear, opacity 0.5s linear .5s;
            opacity: 1;
            top: 10%;

          }

          p {
            opacity: 1;
            top: 40%;
            transition: 500ms ease-in, color 1000ms linear, opacity 0.5s linear .5s;

          }

        }
      }

    }
  }
}


@media (min-width: $medium-min) and (max-width: $medium-screen) {
  .s-ServicesCard {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    width: 70%;
    justify-self: center;
    @include background;


    .s-card {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      background-color: #000000;

      h1 {
        font-size: 4vw;
        color: #ffffff;
        text-align: center;
        position: relative;
        bottom: 9vh;
        width: 24vw;
        font-family: Poppins-Bold;
      }

      .webGameh1 {
        width: 20vw;
      }

      .s-clip {
        z-index: 1;
        position: absolute;
        inset: 0;
        display: flex;
        background-color: $red;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        clip-path: circle(33.7% at 50% 100%);
        transition: clip-path 800ms ease-in, color 1000ms linear;

        //.default-img {
        //  transform: translateY(6vh);
        //  transition: 500ms ease-in, color 1000ms linear;
        //}

        h2 {
          margin-top: 8vh;
          font-size: 4vw;
          font-family: $Poppins-Bold;
          width: 24vw;
          text-align: center;
          margin-top: 0;
          opacity: 0;
          transition: 800ms ease-in-out, color 1000ms linear, opacity 0.2s ease-in-out .3s;
          position: absolute;
          top: 64%;


        }

        .webGameh2 {
          width: 20vw;
        }

        p {
          font-family: $Poppins-Medium;
          font-size: 1.5vw;
          width: 80%;
          text-align: center;
          margin: 0;
          opacity: 0;
          transition: 800ms ease-in-out, color 1000ms linear, opacity 0.2s ease-in-out .3s;
          position: absolute;
          top: 64%;

        }

        //.default-img {
        //  max-width: 6vw;
        //  position: absolute;
        //  top: 64%;
        //  transition: top 800ms ease-in-out;
        //
        //  img {
        //    width: 8vw;
        //  }
        //}

        &:hover {
          clip-path: circle(100%);

          //.default-img {
          //  top: 2%;
          //
          //}

          h2 {
            transition: 500ms ease-in, color 1000ms linear, opacity 0.5s linear .5s;
            opacity: 1;
            top: 10%;

          }

          p {
            opacity: 1;
            top: 40%;
            transition: 500ms ease-in, color 1000ms linear, opacity 0.5s linear .5s;

          }

        }
      }

    }
  }
}
