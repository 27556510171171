
.spinnerContainer {
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;

  .spinner {
    margin: 5%;
    padding: 20px;
  }
}