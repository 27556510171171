@import "../../../../globalScss/global";

@media screen and (min-width: $large-screen) {
  .service-heroSection {
    display: flex;
    width: 90%;
    border-radius: 53px;
    background: linear-gradient(180deg, hsla(345, 100%, 50%, 1) 0%, hsla(0, 0%, 0%, 1) 47%);
    color: #fff;
    justify-content: center;
    overflow-y: hidden;

    .service-main {
      display: flex;
      width: 90%;

      .service-heroSection_left {
        display: flex;
        flex-direction: column;
        width: 60%;
        text-align: start;
        background: url("../../../../assets/servicePage/heropage/Union.png") left -3vw bottom no-repeat;

        h1 {
          font-size: 3.5vw;
          font-family: $Satoshi-Black;
          text-transform: uppercase;
          position: relative;
          top: 20vh;
        }

        h2 {
          font-family: $Satoshi;
          font-size: 2vw;
          letter-spacing: .2vw;
          text-transform: initial;
          position: relative;
          top: 20vh;


        }

        p {
          font-family: $Poppins-Medium;
          font-size: 1.3vw;

        }

      }

      .service-heroSection_right {
        width: 40%;
        background: url("../../../../assets/servicePage/heropage/Union.png") right bottom no-repeat;

        img {
          position: relative;
          bottom: 6.5vh;

        }
      }
    }
  }

}


@media screen and (max-width: $medium-screen) {
  .service-heroSection {
    display: flex;
    width: 90%;
    border-radius: 53px;
    background: linear-gradient(180deg, hsla(345, 100%, 50%, 1) 0%, hsla(0, 0%, 0%, 1) 47%);
    color: #fff;
    justify-content: center;
    overflow-y: hidden;

    .service-main {
      display: flex;
      width: 90%;

      .service-heroSection_left {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        background: url("../../../../assets/servicePage/heropage/Union.png") center bottom  no-repeat;
        align-items: center;
        height: 25vh;

        h1 {
          font-size: 6vw;
          font-family: $Satoshi-Black;
          text-transform: uppercase;
          position: relative;
          margin-bottom: 0;
        }

        h2 {
          font-family: $Satoshi;
          font-size: 3vw;
          letter-spacing: .2vw;
          text-transform: initial;
          position: relative;
          width: 90%;
        }

        p {
          font-family: $Poppins-Medium;
          font-size: 1.3vw;

        }

      }

      .service-heroSection_right {
        display: none;

      }
    }
  }

}