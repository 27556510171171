@font-face {
  font-family: Inter;
  src: local(Inter), url("../font/Inter/OFL.txt") format("truetype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Inter-Regular;
  src: local(Inter-Regular), url("../font/Inter/static/Inter-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Inter-Medium;
  src: local(Inter-Medium), url("../font/Inter/static/Inter-Medium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: Inter-ExtraLight;
  src: local(Inter-ExtraLight), url("../font/Inter/static/Inter-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: Satoshi;
  src: local(Satoshi), url("../font/Satoshi/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Satoshi-Black;
  src: local(Satoshi-Black), url("../font/Satoshi/Satoshi_Complete/Fonts/OTF/Satoshi-Black.otf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Satoshi-Bold;
  src: local(Satoshi-Bold), url("../font/Satoshi/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Satoshi-Light;
  src: local(Satoshi-Light), url("../font/Satoshi/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Satoshi-Medium;
  src: local(Satoshi-Medium), url("../font/Satoshi/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Poppins-Bold;
  src: local(Poppins-Bold), url("../font/Poppins/Poppins-Bold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Poppins-Regular;
  src: local(Poppins-Regular), url("../font/Poppins/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: Poppins-Medium;
  src: local(Poppins-Medium), url("../font/Poppins/Poppins-Medium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: Staatliches-Regular;
  src: local(Staatliches-Regular), url("../font/Staatliches/Staatliches-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;

}



/* Fonts*/

$Inter: Inter;
$Inter-ExtraLight: Inter-ExtraLight;
$Satoshi: Satoshi;
$Satoshi-Light:Satoshi-Light;
$Satoshi-Black: Satoshi-Black;
$Satoshi-Bold:Satoshi-Bold;
$Poppins-Medium:Poppins-Medium;
$Poppins-Bold:Poppins-Bold;
$Poppins-Regular:Poppins-Regular;
$Staatliches-Regular:Staatliches-Regular;

/*Colors*/

$red: #FF2157;
$grey: #d5d0d0;
$input:#262626;
$filter:#6A6666;

/*Breakpoints*/

$small-screen: 480px;
$medium-screen: 768px;
$large-screen:1024px;
$medium-min:481px;


/*Border*/
@mixin background{
  background:
radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
linear-gradient(0deg, #000000 40%, #fff 70%);
  border-radius: 2px;
  padding: 1px;
  box-sizing: border-box;
}


/*Animation*/

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -ms-animation: #{$animation};
  animation: #{$animation};
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}


@include keyframes(bouncebounce) {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(3vh)
  }
}

@include keyframes(slide) {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}


/*Mixins*/

@mixin paragraph {
  font-size: 6.5vw;
  font-family: $Poppins-Medium;
  display: flex;
  margin: 0;
  column-gap: 1.5vw;
}
@mixin heading{
  color: $red;
  font-size: 2.6vw;
  font-family: $Poppins-Bold;
  margin: 0;
}
@mixin mobile-heading{
  color: $red;
  font-size: 3.6vw;
  font-family: $Poppins-Bold;
}
@mixin mobile-paragraph{
  font-size: 6.5vw;
  font-family: $Poppins-Medium;
  display: flex;
  max-width: 25vw;
  margin-bottom: 0;
  column-gap: 1vw;

  div{
    width: 8vw;
  }
}



@mixin wrapper {
  position: absolute;
  top: 0;
  width: 30vw;
  height: 100%;
  content: "";
  z-index: 2;
}