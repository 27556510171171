@import "../../../../../globalScss/global";


/*=============== HEADER ===============*/
.header{
    display: flex;
    width: 100%;
    background-color: black;
    color: white;
    position: relative;
    font-family: $Satoshi;

    z-index: 100;
    font-family: $Satoshi-Black;

}

/*=============== NAV ===============*/
.nav{
    display: flex;

    &__data{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        right: 4vw;
    }
    &__logo{
        display: inline-flex;
        align-items: center;
        column-gap: .25rem;
        transition: color .3s;


    }
    &__toggle{
        position: relative;
        width: 32px;
        height: 32px;
        
        &-menu,
        &-close{
            font-size: 1.8rem;
            position: absolute;
            display: grid;
            place-items: center;
            inset: 0;
            cursor: pointer;
            transition: opacity .1s, transform .4s;
        }
        &-close{
            opacity: 0;
        }
    }



    @media screen and (max-width: 1024px){
        display: flex;
        width: 100%;
        &__data{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__menu{
            background-color: black;
            position: absolute;
            left: 0;
            top: 2.5rem;
            width: 100%;
            height: calc(100vh - 3.5rem);
            overflow: auto;
            padding-block: 1.5rem 4rem;
            pointer-events: none;
            opacity: 0;
            transition: top .4s, opacity .3s;
    
            &::-webkit-scrollbar{
                width: .5rem;
            }
            &::-webkit-scrollbar-thumb{
                background-color: hsl(220, 12%, 70%);
            }
        }
        &__logo{
            img{
                max-width: 70%;
            }
        }
    }
    &__link{
        color: white;
        padding: 1.25rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color .3s;

        &:hover{
        }
    }

}

/* Show menu */

.show-menu{
    opacity: 1;
    top: 3.5rem;
    pointer-events: initial;
}


/* Show icon */
.show-icon .nav__toggle-menu{
    opacity: 0;
    transform: rotate(90deg);
}

.show-icon .nav__toggle-close{
    opacity: 1;
    transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown{
    &__button{
        cursor: pointer;
    }
    &__arrow{
        font-size: 2rem;
        font-weight: initial;
        transition: transform .4s;
        display: flex;

    }
    &__content,
    &__group,
    &__list{
        display: grid;
    }
    &__container{
        background-color: black;
        height: 0;
        overflow: hidden;
        transition: height .4s;
    }
    &__content{
        //row-gap: 1.75rem;
    }
    &__group{
        padding-left: 2.5rem;
        row-gap: .5rem;
        text-align: start;

        &:first-child{
            margin-top: 1.25rem;
        }
        &:last-child{
            margin-bottom: 1.25rem;
        }


    }
    &__list-heading{
        display: flex;
        align-items: center;
        column-gap: 2vw;
        font-size: 6vw;
        font-family: $Satoshi-Black;
        &:hover{
            color: $red;
            cursor: pointer;
        }

    }

    &__title{
        color: white;

    }
    &__list{
        row-gap: 1rem;
        font-size: 4vw;
    }
    &__link{
       color: white;
        transition: color .3s;

        &:hover{
           color: $red;
        }
    }
    //.nav-heading{
    //    &:hover{
    //        color: $red;
    //        cursor: pointer;
    //    }
    //}
}

/* Rotate dropdown icon */
.show-dropdown .dropdown__arrow{
    transform: rotate(180deg);
}
